<template>
  <div class="loginBg">
    <div class="login-content">
      <div class="logo-img">

        <img v-if="singer" src="@/assets/images/login/logo2.png" alt="" />
        <img v-else src="@/assets/images/login/logo.png" alt="" />
      </div>
      <div class="login-form">
        <div class="login-title">智慧餐厨处理监管平台</div>
        <div class="box">
          <el-form
            class="loginForm"
            :model="loginForm"
            :rules="rules"
            ref="loginForm"
            label-position="top"
            :inline-message="false"
            :hide-required-asterisk="true"
          >
            <el-form-item label="用户名" prop="username">
              <el-input
                v-model="loginForm.username"
                placeholder="请输入用户名"
                autocomplete="off"
              >
                <i slot="prefix" class="el-input__icon el-icon-user-solid"></i
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                type="password"
                v-model="loginForm.password"
                :show-password="true"
                autocomplete="off"
                placeholder="请输入密码"
              >
                <i slot="prefix" class="el-input__icon el-icon-unlock"></i
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                class="loginBtn"
                type="primary"
                @click="submitForm('loginForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/login";
// import md5 from 'js-md5'
export default {
  data() {
    return {
      singer:'',
      loginForm: {
        username: "",
        password: ""
      },
      codeUrl: "",
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      }
    };
  },



  mounted() {
//        let data={
//           username:'xxxx',
//           password:'xxxx'
//        }
//       //  转JSON
//        data=JSON.stringify(data)
// // encodeURI 编码
//        data=encodeURI(data);
// // base64编码
//       data = window.btoa(data) 
     

      // let timeStamp=this.$route.query.appId
      let code=this.$route.query.appSecret

      //  let singer=this.$route.query.singer

      // if(singer){
      //   this.singer=singer
      //   sessionStorage.setItem('singer',singer)
      // }
     if(code){
        this.singer=code
        sessionStorage.setItem('singer',code)
        code=window.atob(code)
        code=decodeURI(code)
        code=JSON.parse(code)
        this.loginForm.username=code.username
         this.loginForm.password=code.password
        this.submitForm('loginForm')
     }


      // if(code&&timeStamp){
      //   let str = md5(code+timeStamp)
      //   code=window.atob(code)
      //   code=decodeURI(code)
      //   code=JSON.parse(code)
      //   let newtime= Date.now()
      //   timeStamp=timeStamp.length==13?timeStamp*1:timeStamp*1000
      //  if(newtime  < (timeStamp+ 1 * 10 * 1000)){                
      //       if(str==singer){
      //               this.loginForm.username=code.username
      //               this.loginForm.password=code.password
      //               this.submitForm('loginForm')
      //        }else{
      //             console.log(str)
      //             this.$message.error('数据不合法')
      //         }
      //  }else{
      //     this.$message.error('链接超时')
      //  }
      // }






  },
  methods: {
    // 登录
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loginForm.userType = "03";
          // this.$store.commit("SAVE_TOKEN", "123456");
          // this.$router.replace("/");
          login(this.loginForm).then(res => {
            if (res.code === 200) {
              this.$store.commit("SAVE_TOKEN", res.token);
              this.$router.replace("/");
            } else {
              this.$message.error({
                message: res.msg,
                center: true
              });
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.loginBg {
  width: 100%;
  height: 100vh;
  background: url("~@/assets/images/login/login_bg.jpg") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.login-content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-img {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 205px;
  height: 60px;
  img {
    width: 100%;
    height: 100%;
  }
}
.login-form {
  position: relative;
  top: 11.5vh;
  width: 100%;
}
.login-title {
  text-align: center;
  font-size: 51px;
  background: -webkit-linear-gradient(-90deg, #fff, #a9edfe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 55px;
}
.box {
  width: 530px;
  // height: 500px;
  padding: 60px;
  background: rgba(6, 25, 90, 0.9);
  border: 1px solid #4563c2;
  box-shadow: 0px 0px 35px 0px rgba(140, 148, 228, 0.23);
  border-radius: 10px;
  margin: 0 auto;
}
.loginBtn {
  margin-top: 60px;
  background: linear-gradient(-63deg, #4ec2e4 0%, #3dafff 100%);
  box-shadow: 0px 0px 16px 0px rgba(16, 19, 50, 0.28);
  width: 100%;
  height: 50px;
}
</style>
<style lang="scss">
.loginForm {
  .el-input__inner {
    background: #243b89;
    border: 1px solid #3c58b8;
    height: 50px;
    line-height: 50px;
    color: #fff;
  }
}
</style>
